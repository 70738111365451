/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 21, 2017 */


@font-face {
    font-family: 'axiformablack_italic';
    src: url('kastelov_-_axiforma_black_italic-webfont.woff2') format('woff2'),
    url('kastelov_-_axiforma_black_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'axiformablack';
    src: url('kastelov_-_axiforma_black-webfont.woff2') format('woff2'),
    url('kastelov_-_axiforma_black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'axiformabold_italic';
    src: url('kastelov_-_axiforma_bold_italic-webfont.woff2') format('woff2'),
    url('kastelov_-_axiforma_bold_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'axiformabold';
    src: url('kastelov_-_axiforma_bold-webfont.woff2') format('woff2'),
    url('kastelov_-_axiforma_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'axiformabook_italic';
    src: url('kastelov_-_axiforma_book_italic-webfont.woff2') format('woff2'),
    url('kastelov_-_axiforma_book_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'axiformabook';
    src: url('kastelov_-_axiforma_book-webfont.woff2') format('woff2'),
    url('kastelov_-_axiforma_book-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'axiformaextrabold_italic';
    src: url('kastelov_-_axiforma_extrabold_italic-webfont.woff2') format('woff2'),
    url('kastelov_-_axiforma_extrabold_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'axiformaextrabold';
    src: url('kastelov_-_axiforma_extrabold-webfont.woff2') format('woff2'),
    url('kastelov_-_axiforma_extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'axiformaheavy_italic';
    src: url('kastelov_-_axiforma_heavy_italic-webfont.woff2') format('woff2'),
    url('kastelov_-_axiforma_heavy_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'axiformaheavy';
    src: url('kastelov_-_axiforma_heavy-webfont.woff2') format('woff2'),
    url('kastelov_-_axiforma_heavy-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'axiformaitalic';
    src: url('kastelov_-_axiforma_italic-webfont.woff2') format('woff2'),
    url('kastelov_-_axiforma_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'axiformalight_italic';
    src: url('kastelov_-_axiforma_light_italic-webfont.woff2') format('woff2'),
    url('kastelov_-_axiforma_light_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'axiformalight';
    src: url('kastelov_-_axiforma_light-webfont.woff2') format('woff2'),
    url('kastelov_-_axiforma_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'axiformamedium_italic';
    src: url('kastelov_-_axiforma_medium_italic-webfont.woff2') format('woff2'),
    url('kastelov_-_axiforma_medium_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'axiformamedium';
    src: url('kastelov_-_axiforma_medium-webfont.woff2') format('woff2'),
    url('kastelov_-_axiforma_medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'axiformaregular';
    src: url('kastelov_-_axiforma_regular-webfont.woff2') format('woff2'),
    url('kastelov_-_axiforma_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'axiformasemibold_italic';
    src: url('kastelov_-_axiforma_semibold_italic-webfont.woff2') format('woff2'),
    url('kastelov_-_axiforma_semibold_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'axiformasemibold';
    src: url('kastelov_-_axiforma_semibold-webfont.woff2') format('woff2'),
    url('kastelov_-_axiforma_semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'axiformathin_italic';
    src: url('kastelov_-_axiforma_thin_italic-webfont.woff2') format('woff2'),
    url('kastelov_-_axiforma_thin_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}



@font-face {
	font-family: 'axiformathin';
	src: url('kastelov_-_axiforma_thin-webfont.woff2') format('woff2'),
	url('kastelov_-_axiforma_thin-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;

}


@font-face {
	font-family: 'ZeitungPro-ExtraBold';
	src: url('Zeitung/ZeitungPro-ExtraBold.otf') format('truetype');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'ZeitungPro-Black';
	src: url('Zeitung/ZeitungPro-Black.otf') format('truetype');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'ZeitungPro-Bold';
	src: url('Zeitung/ZeitungPro-Bold.otf') format('truetype');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'ZeitungPro-Italic';
	src: url('Zeitung/ZeitungPro-Italic.otf') format('truetype');
	font-weight: normal;
	font-style: normal;
}






