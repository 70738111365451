/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "assets/font/stylesheet.css";
@import "~swiper/swiper-bundle.css";

@import url("https://use.typekit.net/tdw5vhr.css");

@import "node_modules/@marcreichel/angular-carousel/css/main.css";
@import "node_modules/@marcreichel/angular-carousel/themes/stripes.css";
@import "node_modules/@marcreichel/angular-carousel/themes/dots.css";

::-webkit-scrollbar,
*::-webkit-scrollbar {
	//display: none;
}

::-webkit-scrollbar {
	width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px #fff;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #B4B4B4;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #fff;
}

.no-scroll {

	--offset-bottom: auto !important;

	--overflow: hidden;

	overflow: auto;

	&::-webkit-scrollbar {

		display: none;

	}
}

html {
	background: white;
}

.connected {
	height: 12px;
	width: 12px;
	border-radius: 100%;
	background-color: #2ecc71;
	border: solid 2.5px white;
	position: absolute;
	bottom: 0;
	right: 1px;
}

ion-content {
	--ion-background-color: var(--cftc-color-8) !important;
	background: var(--cftc-color-8);
	--background: var(--cftc-color-8);
	--offset-top: 1px !important;
}

.popover-header {
	//--background: red;
	--backdrop-opacity: 0;
	//margin-top: 10px;
	--width: 165px;
	--border-radius: 0;
	--box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.destop-ion-content {
	--background: var(--cftc-color-9) !important;
}

.desktop-ion-input {
	--background: var(--cftc-color-4) !important;
	--color: var(--cftc-color-3) !important;
	--placeholder-color: var(--cftc-color-3) !important;
}

ion-input {
	--background: var(--cftc-color-8) !important;
	--border-radius: 8px !important;
	--color: var(--cftc-color-11) !important;
	--placeholder-color: var(--cftc-color-11) !important;
	font-size: 14px !important;
	font-family: "axiformaregular", sans-serif !important;
	height: 58px !important;
	--padding-start: 20px !important;
	letter-spacing: -1px !important;
}

ion-input input {
	--padding-start: 25px !important;
}

.popover-desktop {
	--width: 400px;
}

ion-button {
	height: 58px;
	border-radius: 29px !important;
	--ion-button-round-border-radius: 29px !important;
	--ion-button-border-radius: 29px !important;
	--ion-border-radius: 29px !important;
	--border-radius: 29px !important;
	--box-shadow: none !important;
	border: solid 2px transparent !important;
	text-transform: none !important;
	font-size: 14px;
	font-family: "axiformabold";
}


.bg-yellow {
	background: var(--cftc-color-6) !important;
	--background: var(--cftc-color-6) !important;
}

.bg-white {
	background: var(--cftc-color-12) !important;
	--background: var(--cftc-color-12) !important;
}

.bg-transparent {
	background: transparent !important;
	--background: transparent !important;
}

.bg-blue {
	background: var(--cftc-color-9) !important;
	--background: var(--cftc-color-9) !important;
}

.bg-gray {
	background: #b4b4b4 !important;
	--background: #b4b4b4 !important;
}

.bg-red {
	background: #f04c71 !important;
	--background: #f04c71 !important;
}

.bg-maron {
	background: var(--cftc-color-7) !important;
	--background: var(--cftc-color-7) !important;
}

.bd-green {
	border-color: var(--cftc-color-1) !important;
	--border-color: var(--cftc-color-1) !important;
}

.bd-gray {
	border-color: #b4b4b4 !important;
	--border-color: #b4b4b4 !important;
}

.bd-blue {
	border-color: var(--cftc-color-9) !important;
	--border-color: var(--cftc-color-9) !important;
}

.bd-red {
	border-color: #f04c71 !important;
	--border-color: #f04c71 !important;
}

.bd-yellow {
	border-color: var(--cftc-color-6) !important;
	--border-color: var(--cftc-color-6) !important;
}

.bd-transparent {
	border-color: transparent !important;
	--border-color: transparent !important;
}

.bd-maron {
	border-color: var(--cftc-color-7) !important;
	--border-color: var(--cftc-color-7) !important;
}

.text-green {
	color: var(--cftc-color-1) !important;
	--color: var(--cftc-color-1) !important;
}

.text-blue {
	color: var(--cftc-color-9) !important;
	--color: var(--cftc-color-9) !important;
}

.text-maron {
	color: var(--cftc-color-7) !important;
	--color: var(--cftc-color-7) !important;
}

.text-gray {
	color: #b4b4b4 !important;
	--color: #b4b4b4 !important;
}

.text-red {
	color: #f04c71 !important;
	--color: #f04c71 !important;
}

.text-yellow {
	color: var(--cftc-color-6) !important;
	--color: var(--cftc-color-6) !important;
}

.text-white {
	color: var(--cftc-color-8) !important;
	--color: var(--cftc-color-8) !important;
}

.checkbox {
	--background: transparent;
	background: transparent;
	border: none;
}

.checkbox ion-label {
	color: var(--cftc-color-11) !important;
	--color: var(--cftc-color-11) !important;
	font-size: 12px !important;
	font-family: "axiformaregular" !important;
	line-height: 16px;
}

.checkbox .checkbox-icon {
	border-radius: 6px !important;
}

.checkbox ion-checkbox {
	width: 29px !important;
	--background: var(--cftc-color-8) !important;
	--height: 25px !important;
	--width: 25px !important;
	--checkmark-color: var(--cftc-color-1) !important;
	// --background-checked: var(--cftc-color-1) !important;
	--background-checked: var(--cftc-color-8) !important;
	--border-color: var(--cftc-color-8) !important;
	--border-color-checked: var(--cftc-color-8) !important;
	--border-radius: 6px !important;
}

ion-card {
	background: #fff;
	--background: #fff;
	//box-shadow:none;
}

.modal-profile-css {
	padding-top: 40px;
	position: absolute;
	--width: 100% !important;
	--height: 100% !important;
	--border-radius: 15px !important;
	top: 0 !important;
	right: 0 !important;
	--backdrop-opacity: 0.7;
}

.modal-access-css {
	padding-top: 40px;
	position: absolute;
	--width: 100% !important;
	--height: 100% !important;
	--border-radius: 15px !important;
	top: 0 !important;
	right: 0 !important;
	--backdrop-opacity: 0.7;
}

.modal-access-desktop-css {
	padding-top: 40px;
	position: absolute;
	--height: 100% !important;
	--border-radius: 15px !important;
	top: 0 !important;
	right: 0 !important;
	--backdrop-opacity: 0.7;
}

.modal-thematic-css {
	padding-top: 40px;
	--backdrop-opacity: 0.7;
	--border-radius: 15px 15px 0px 0px !important;
}

.modal-cse-css {
	padding-top: 40px;
	--backdrop-opacity: 0.7;
	--border-radius: 15px !important;
}

.modal-cse-css-desktop {
	padding: 40px 50px 70px;
	--backdrop-opacity: 0.7;
	--border-radius: 15px !important;
	--height: 100%;
	--width: 1150px;
	--overflow-y: hidden;
}

.modal-cse-confirm-css {
	padding-top: calc(100vh - 368px) !important;
	--backdrop-opacity: 0.7 !important;
	--border-radius: 15px !important;
}

.modal-cse-confirm-css-desktop {
	padding: 40px 50px 90px;
	--backdrop-opacity: 0.7;
	--border-radius: 15px !important;
	--height: 342px;
	--width: 1157px;
}

.modal-coup-midi {
	height: 220px;
	--border-radius: 10px !important;
	margin: auto;
	max-width: 90%;
}

.modal-coup-midi-confirm-css-desktop {
	padding: 40px 50px 90px;
	--backdrop-opacity: 0.7;
	--border-radius: 15px !important;
	--height: 342px;
	--width: 1157px;
}

.modal-coup-midi-confirm-css-mobile {
	padding-left: 25px;
	padding-right: 25px;
	--border-radius: 15px !important;
	--height: 218px;
	--width: 100%;
}

ion-modal.modal-default.show-modal ~ ion-modal.modal-default {
	--backdrop-opacity: 0.7;
	--box-shadow: none;
}

.modal-chat-find-css {
	height: 103px;
	--backdrop-opacity: 0.5;
	--background: transparent;
}

.modal-update-new-css {
	--background: var(--cftc-color-9);
}

.modal-chat-find-css ion-backdrop {
	background-color: rgba(0, 0, 0, 1);
}

.modal-profile-css ion-backdrop {
	background-color: rgba(0, 0, 0, 1);
}

.modal-css {
	--border-radius: 15px !important;
	padding-top: 60px !important;
	--padding-top: 60px !important;
}

.modal-css ion-toolbar {
	padding-top: 0 !important;
}

.modal-css ion-content {
	background: #fff;
	--background: #fff;
}

.modal-css ion-title {
	color: var(--cftc-color-10) !important;
	--color: var(--cftc-color-10) !important;
	font-size: 14px !important;
	font-family: "axiformabold" !important;
}

.swiper-pagination {
	position: absolute;
	bottom: 5px !important;
	// bottom: 40px !important;
	// padding-bottom: 3px;
}

.swiper-pagination-bullet {
	--bullet-background: var(--cftc-color-1);
	background: var(--cftc-color-1);
	width: 10px;
	height: 10px;
	opacity: 1;
	margin-bottom: 10px;
	top: 0;
}

.swiper-pagination-bullet-active {
	--bullet-background-active: var(--cftc-color-6);
	background: var(--cftc-color-6);
	border-radius: 10px;
	width: 30px;
	height: 10px;
	margin-bottom: 10px;
}

.onboarding-button {
	--background: var(--cftc-color-6) !important;
	background: var(--cftc-color-6) !important;
	width: 100% !important;
}

ion-slides {
	min-height: 100%;
}

.map-label {
	color: red;
	background-color: #eeeff160;
	border-radius: 13px;
	max-width: 100px;
	padding: 5px 15px 5px 15px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.map-label-event {
	color: red;
	background-color: #eeeff160;
	border-radius: 13px;
	max-width: 100px;
	padding: 5px 15px 5px 15px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.map-label-user {
	background-color: #eeeff160;
	border-radius: 13px;
	max-width: 100px;
	padding: 5px 15px 5px 15px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.map-info-window-to-marker {
	background-color: red;
	position: absolute;
	bottom: 50px;
}

.list-ios {
	background: transparent !important;
	--background: transparent !important;
}

.popover-chat-channel {
	--width: 276px;
}

.route {
	font-size: 12px !important;
	font-family: "axiformaregular" !important;
	color: var(--cftc-color-9) !important;
}

.route a {
	font-size: 12px !important;
	font-family: "axiformaregular" !important;
	color: var(--cftc-color-9) !important;
	text-decoration: none;
}

.files {
	background: transparent !important;
	--background: transparent !important;
	--min-height: 17px !important;
	--max-height: 17px !important;
	--inner-padding-end: 0px !important;
	--inner-padding-start: 0px !important;
	--padding-start: 0;
	padding: 0 !important;
	margin-bottom: 5px;

	ion-icon {
		font-size: 20px !important;
		color: var(--cftc-color-13) !important;
		--color: var(--cftc-color-13) !important;
	}

	a {
		font-size: 12px !important;
		font-family: "axiformaregular" !important;
		color: var(--cftc-color-9) !important;
		--color: var(--cftc-color-9) !important;
		margin: 0;
	}

	ion-label {
		font-size: 12px !important;
		font-family: "axiformaregular" !important;
		color: var(--cftc-color-9) !important;
		--color: var(--cftc-color-9) !important;
		margin: 0;
	}
}

.http-link-white {
	color: var(--cftc-color-12);
}

.http-link-blue {
	color: var(--cftc-color-9);
}

.modal-notification-new-css {
	background: transparent !important;
	--background: transparent !important;
	--backdrop-opacity: 0.5 !important;

}

.modal-notification-new-css-desktop {
	background: transparent !important;
	--background: transparent !important;
	--backdrop-opacity: 0.5 !important;

	&::part(content) {
		height: 300px !important;
	}
}


.modal-signalement-css {
	background: transparent !important;
	--background: transparent !important;
	--backdrop-opacity: 0.5 !important;
	--box-shadow: none !important;
}

.modal-signalement-css-desktop {
	background: transparent !important;
	--background: transparent !important;
	--backdrop-opacity: 0.5 !important;
	--box-shadow: none !important;
}

.modal-interstitiel-css-desktop {
	background: transparent !important;
	--background: transparent !important;
	--backdrop-opacity: 0.5 !important;
	--box-shadow: none !important;
	--height: 80% !important;
	--width: 367px !important;
	--border-radius: 10px !important;
}

.modal-interstitiel-css {
	background: transparent !important;
	--background: transparent !important;
	--backdrop-opacity: 0.5 !important;
}

.desktop-title {
	margin-bottom: 10px;

	ion-item {
		--background: transparent !important;
		--padding-start: 0 !important;
		padding-start: 0 !important;
		padding-left: 0 !important;
		--padding-left: 0 !important;
		padding: 0 !important;
		--padding: 0 !important;
		padding-inline-start: 0 !important;
		--padding-inline-start: 0 !important;

		ion-img {
			height: 22px !important;
			margin-right: 5px !important;
		}

		ion-label {
			--color: var(--cftc-color-9) !important;
			color: var(--cftc-color-9) !important;
			font-size: 28px !important;
			font-family: ZeitungPro-ExtraBold, sans-serif !important;
			margin: 0 !important;
			--margin: 0 !important;
		}
	}
}

.desktop-breadcrumb {
	text-decoration: none !important;
	margin-bottom: 5px !important;
	--color: var(--cftc-color-9) !important;
	color: var(--cftc-color-9) !important;
	font-size: 12px !important;
	font-family: 'axiformaregular' !important;

	a {
		--color: var(--cftc-color-9) !important;
		color: var(--cftc-color-9) !important;
		text-decoration: none !important;
		font-size: 12px !important;
		font-family: 'axiformaregular' !important;
	}

	b {
		font-family: "axiformabold" !important;
	}
}

/************************************** izhak css cftc ****************************************/
/* Titre de l'cftc */
h1.small {
	font-size: 3.75em;
	color: #114f9f;
}

/* Texte en gras en dessous du site */
.the_excerpt {
	font-weight: bold;
	font-size: 1.125em;
	margin: 30px 0;
}

/* Bloc lire la suite de l'cftc */
.bloc-gofurther {
	margin: 40px 0;
	padding: 25px;
	background-color: rgba(255, 255, 255, 0.5);
	border: 5px solid #114F9F;
}

/*Encadré gris */

.bloc-background {
	margin: 40px 0;
	padding: 30px;
	background-color: #F1F1F1;
}

.bloc-background h3 {
	color: #114F9F !important;
}

.bloc-quote {
	margin-bottom: 40px;
	color: #114F9F;
	font-size: 1.25em;
	font-style: italic;
	font-weight: bold;
	padding: 0 40px 0 60px;
}

.modal-delete-account-css {

	--border-radius: 15px !important;
	padding-top: 55px !important;
	--padding-top: 55px !important;
}

.modal-delete-account-css-desktop {

	--border-radius: 15px !important;

}

.modal-reactive-account-css {
	--border-radius: 15px 15px 0 0;
	border-radius: 15px 15px 0 0;

	background: transparent !important;
	--background: transparent !important;
	--backdrop-opacity: 0.5 !important;
}

.modal-reactive-account-css-desktop {
	background: transparent !important;
	--background: transparent !important;
	--backdrop-opacity: 0.5 !important;
	--box-shadow: none !important;

	--border-radius: 15px;
	border-radius: 15px;

	padding-bottom: calc(100vh - 520px) !important;

	// --height: 40% !important;
}

/**********************************************************************************************/
@media only screen and (min-width: 768px) and (min-height: 600px) {
	.modal-thematic-css-desktop {
		--width: 70% !important;
		--height: 85% !important;
		--border-radius: 8px;
	}
	.modal-thematic-bottom-css-desktop {
		--width: 70% !important;
		--height: 100% !important;
		padding-top: 5%;
		--border-radius: 8px;
	}
	.modal-cftc-css-desktop {
		--width: 100% !important;
		--height: 100% !important;
	}

	.modal-css-desktop {
		position: absolute;
		--width: 300px !important;
		--height: calc(100% - 68px) !important;
		top: 68px;
		right: 0 !important;

	}

	.modal-profile-css-desktop {
		position: absolute;
		--width: 100% !important;
		--height: 100% !important;
		top: 0 !important;
		right: 0 !important;
	}

	.modal-article-css-desktop {
		--height: 100% !important;
		--width: 100% !important;
		position: absolute;
		top: 0 !important;
		right: 0 !important;
		--background: var(--cftc-color-8) !important;
	}
	.modal-event-css-desktop {
		--width: 80% !important;
		--height: 80% !important;
		--border-radius: 15px !important;
	}
	.modal-event-css-desktop-open {
		--height: 80% !important;
		--border-radius: 15px !important;
	}
	.modal-cftc-css-qrcode-desktop {
		--height: 70% !important;
	}
	.modal-notif-css-desktop {
		--border-radius: 15px !important;
		--height: 55% !important;
	}
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
	display: contents;
}

.carousel__previous {
	display: none !important;
}

.carousel__next {
	display: none !important;
}

.carousel__slide {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.carousel__bullets {
	position: absolute !important;
	top: 30px !important;
	left: 0 !important;
	right: 0 !important;
	margin: auto 25px !important;

	.carousel__bullet {
		width: 66px !important;
		height: 3px !important;
		background-color: rgba(255, 255, 255, 1) !important;
		border-radius: 3px !important;
	}
}

// --- Desktop SOMMAIRE ---

.select-item-popover ion-select-popover ion-list {
	background: #EDEFF6;
}

.select-item-popover ion-select-popover ion-item {
	--background: #EDEFF6;
	--inner-border-width: 0;
	font-family: axiformabold, sans-serif !important;
	font-size: 16px;
	--color: var(--cftc-color-9);
	line-height: 19px;
	--background-focused: none !important;
	--background-hover: none !important;
	--padding-start: 26px;

	&.item-radio-checked {
		--background: #F0FFFF !important;
		--color: var(--cftc-color-20) !important;
	}
}

.select-item-popover {
	--width: 362px;
	margin-left: 17px;
	--box-shadow: 0 -3px 6px rgba(255, 255, 255, 0.20);
	--background: unset;
}

.select-item-popover::part(backdrop) {
	--background: transparent;
	background: transparent;
}


// --- Mobile SOMMAIRE ---

.select-item-popover-mobile ion-select-popover ion-list {
	background: #EDEFF6;
}

.select-item-popover-mobile ion-select-popover ion-item {
	--background: #EDEFF6;
	--inner-border-width: 0;
	font-family: axiformabold, sans-serif !important;
	font-size: 16px;
	--color: var(--cftc-color-9);
	line-height: 19px;
	--background-focused: none !important;
	--background-hover: none !important;
	--padding-start: 26px;
	margin-top: -1px;

	&.item-radio-checked {
		--background: #F0FFFF !important;
		--color: var(--cftc-color-20) !important;
	}
}

.select-item-popover-mobile ion-select-popover ion-item ion-radio {
	--color: transparent;
	--color-checked: transparent;
}

.select-item-popover-mobile {
	margin-top: -5px;
	/*--max-width: 100% !important;*/
	width: var(--width);
	margin-left: auto;
	margin-right: auto;
	--width: calc(100vw - 52px) !important;
	--box-shadow: 0 -3px 6px rgba(255, 255, 255, 0.20);
	--background: unset;

	ion-select-popover {
		margin-right: 26px !important;
	}
}

.select-item-popover-mobile::part(backdrop) {
	--background: transparent;
	background: transparent;
}


.textarea-wrapper::after {
	word-break: break-word;
}

.native-textarea {
	word-break: break-word;
}

.popover-mobile-cftc-chat-channel {
	--width: 250px;
}
